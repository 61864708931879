// roles.js
import {
  collection,
  query,
  where,
  orderBy,
  startAfter,
  limit,
  getDocs,
  getDoc,
  setDoc,
  updateDoc,
  deleteDoc,
} from 'firebase/firestore'
import { db } from '../../../../src/firebase/firebaseapi'
import { COLLECTIONS, ROLE } from '../../constants/constants'

export const getAllRoles = async (limitValue = 10, startAfterDoc = null) => {
  try {
    const rolesCollectionRef = collection(db, COLLECTIONS.ROLES)
    const queryConstraints = [where('type', '==', ROLE.ROLE_TYPE_HUB), orderBy('createdAt'), limit(limitValue)]

    if (startAfterDoc) {
      queryConstraints.push(startAfter(startAfterDoc))
    }

    const rolesQuery = query(rolesCollectionRef, ...queryConstraints)
    const querySnapshot = await getDocs(rolesQuery)

    const data = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
    const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1]

    return {
      data,
      lastVisible,
    }
  } catch (error) {
    console.error('Error fetching role data:', error)
    return { data: [] }
  }
}
export const getUserRole = async (userId, type) => {
  try {
    const roleRef = doc(db, COLLECTIONS.USERS, userId, COLLECTIONS.ROLES, type)
    const roleDoc = await getDoc(roleRef)
    return roleDoc.exists() ? roleDoc.data() : null
  } catch (error) {
    console.error('Error fetching role data:', error)
  }
}

// Create a new role document in the ROLES collection
export const createRole = async (role) => {
  try {
    const roleRef = doc(collection(db, COLLECTIONS.ROLES)) // Auto-generates an ID
    await setDoc(roleRef, { ...role, id: roleRef.id })
    return true
  } catch (error) {
    console.error('Error creating role data:', error)
    return false
  }
}

// Edit an existing role document by ID
export const editRole = async (id, role) => {
  try {
    const roleRef = doc(db, COLLECTIONS.ROLES, id)
    await updateDoc(roleRef, role)
    return true
  } catch (error) {
    console.error('Error updating role data:', error)
    return false
  }
}

// Delete a role document by ID
export const deleteRole = async (idToRemove) => {
  try {
    const roleRef = doc(db, COLLECTIONS.ROLES, idToRemove)
    await deleteDoc(roleRef)
    return true
  } catch (error) {
    console.error('Error deleting role data:', error)
    return false
  }
}
